
import { Component, Prop, Vue } from 'vue-property-decorator';
import GButton from '@/components/gsk-components/GskButton.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import UserCircle from '@/components/UserCircle.vue';
import UserList from '@/components/UserList.vue';
import GAutocomplete from '@/components/gsk-components/GskAutocomplete.vue';
import { TeamsModule } from '@/store/modules/teams.module';
import { ProjectTeam, TeamMember } from '@/types/teams.types';
import { SelectOption } from '@/components/form/form.types';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { RoleNames, Roles } from '@/constants';
import { UserModule } from '@/store/modules/user.module';
import { User } from '@/types/users.types';


interface TeamSelectOption {
  label: string;
  value: number;
}

@Component({
  components: {
    GButton,
    UserCircle,
    UserList,
    GPeoplePicker,
    GAutocomplete,
    GSelect,
  },
})
export default class TeamPermissions extends Vue {
  @Prop(Array) teams!: ProjectTeam[];
  @Prop(Array) allowedRoles!: {id:number, name:string}[];
  @Prop(Boolean) isProject!: boolean;
  public searchQuery: { label: string; value: string } | undefined | null = null;
  public teamOptions: ProjectTeam[] = [];
  public selectedTeam: TeamSelectOption | null = null;
  public teamRoleOptions: SelectOption[] = [
    { value: Roles.ProjectViewer + '', label: RoleNames.ProjectViewer },
    { value: Roles.ProjectEditor + '', label: RoleNames.ProjectEditor },
  ];
  public ListingRoleNames: SelectOption[] = [
    { value: Roles.Owner + '', label: RoleNames.Owner },
    { value: Roles.ListViewer + '', label: RoleNames.ListViewer },
  ];

  get disabled(): boolean {
    return false;
  }
  get ownerRoleName(): string {
    return RoleNames.Owner;
  }
  get currentUser(): User {
    return UserModule.user;
  }
  updateUserRole(m: TeamMember, e: string) {
    const newTeamRoleId = Number(e);
    m.roleId = newTeamRoleId;
  }

  public async searchTeams(value: string): Promise<{ label: string; value: string }[]> {
    if (value) {
      const response = (await TeamsModule.searchTeams(value)) || [];
      this.teamOptions = response;
      return response.map(team => {
        return {
          label: team.teamName,
          value: team.teamId + '',
        };
      });
    }
    return [];
  }

  public getTeams(e: CustomEvent): void {
    let { selectedChoice } = e.detail;
    selectedChoice.value = Number(selectedChoice.value);
    this.selectedTeam = selectedChoice;
  }

  addTeam() {
    this.teamOptions.forEach(team => {
      if (team.teamId === Number(this.selectedTeam?.value)) {
        team.roleId = Roles.Owner;
        this.$emit('add', team);
      }
    });
    this.selectedTeam = null;
  }

  updateTeamRole(team: ProjectTeam, roleId: string) {
    team.roleId = Number(roleId);
    this.$emit('update', team);
  }

  deleteTeam(team: ProjectTeam) {
    const teamsList: ProjectTeam[] = this.teams.filter(t => {
      return t.teamId !== team.teamId;
    });
    this.$emit('delete', teamsList);
  }
  mounted() {
    this.ListingRoleNames = this.allowedRoles.map(role => ({
      value: role.id.toString(),
      label: role.name,
    }));
  }
}
