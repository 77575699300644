
import { Component, Prop, Vue } from 'vue-property-decorator';
import GButton from '@/components/gsk-components/GskButton.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import { RoleNames, Roles } from '@/constants';
import UserCircle from '@/components/UserCircle.vue';
import UserList from '@/components/UserList.vue';
import { ProjectUser } from '@/types/projects.types';
import { SelectOption } from '@/components/form/form.types';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { UserModule } from '@/store/modules/user.module';
import { BaseUser } from '@/types/users.types';
import { TeamMember } from '@/types/teams.types';
import { EnumsModule } from '@/store/modules/enums.module';

@Component({
  components: {
    GButton,
    UserCircle,
    UserList,
    GPeoplePicker,
    GSelect,
  },
})
export default class MemberPermissions extends Vue {
  @Prop(Array) owners!: ProjectUser[];
  @Prop({ type: Boolean, default: false }) listingRole!: boolean;
  @Prop(Boolean) isProject!: boolean;
  @Prop(Boolean) readonly disable!: boolean;
  @Prop(Boolean) readonly validLength!: boolean;
  public newMembers: ProjectUser[] = [];
  private teamMembers: TeamMember[] = [];

  public memberRoleOptions: SelectOption[] = [
    { value: Roles.ProjectViewer + '', label: RoleNames.ProjectViewer },
    { value: Roles.ProjectEditor + '', label: RoleNames.ProjectEditor },
  ];
  public ListingRoleNames: { label: string | RoleNames; value: string | number }[] = [
    { value: Roles.Owner + '', label: RoleNames.Owner },
    { value: Roles.ListViewer + '', label: RoleNames.ListViewer },
  ];

  get disabled(): boolean {
    return this.disable;
  }
  get ownerRoleName(): string {
    return RoleNames.Owner;
  }
  get currentUserMudId(): string {
    return this.currentUser.mudId;
  }
  get currentUser(): BaseUser {
    return UserModule.user;
  }

  get disableAddMemberButton(): boolean {
    return this.newMembers.length === 0;
  }

  ownerRoleEnum(mnemonic: string): number {
    return EnumsModule.enums.role[mnemonic]?.id ?? -1;
  }

  isCurrentUser(member: TeamMember): boolean {
    return member.mudId === this.currentUserMudId;
  }

  updateUserRole(m: TeamMember, e: string) {
    const newTeamRoleId = Number(e);
    const role = this.ListingRoleNames.find(r => r.value.toString() === newTeamRoleId.toString());
    m.roleId = newTeamRoleId;
    m.roleName = role?.label;
  }

  addMembers() {
    let addMembers: ProjectUser[] = this.owners;
    this.newMembers.forEach((member: ProjectUser) => {
      const memberExist = this.owners.some(user => user.mudId === member.mudId);
      if (!memberExist) {
        const { mudId, email, firstName, lastName, fullName } = member;
        addMembers.push({
          roleId: Roles.Owner,
          roleName: RoleNames.Owner,
          mudId,
          email,
          firstName,
          lastName,
          fullName,
        });
      }
    });
    this.$emit('add', addMembers);
    this.newMembers = [];
  }

  deleteMember(member: ProjectUser): void {
    const memberList: ProjectUser[] = this.owners.filter(m => {
      return m.mudId !== member.mudId;
    });
    this.$emit('remove', memberList);
  }
  mounted() {
    let roles = [EnumsModule.enums.role.OWNER, EnumsModule.enums.role.LISTINGVIEWER];

    if (this.isProject) {
      roles = [EnumsModule.enums.role.OWNER, EnumsModule.enums.role.PROJECTVIEWER];
    }

    this.ListingRoleNames = roles.map(role => ({
      value: role.id,
      label: role.name?.charAt(0)?.toUpperCase() + role.name?.slice(1),
    }));
  }
}
